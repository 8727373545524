<template>
  <div style="padding: 0 20px;">
    <div class="title">{{$t('pledgeList.title')}}</div>
      <!-- 切换 -->
      <div class="flex_lr">
        <div class="nav flex_center hand" @click="nav=0" :style="nav==0?'background:#1332B7;color:#ffffff;':''">MT</div>
        <div class="nav flex_center hand" @click="nav=1" :style="nav==1?'background:#1332B7;color:#ffffff;':''">ULME</div>
      </div>
    <div class="pledge" v-if="nav==0">
      <div class="pledge_box" style="padding-bottom: 10px;margin-bottom:20px;" v-for="(item,index) in info.deposit_mt" :key="index">

        <div class="pledge_top flex_lr">
          <div class="flex_row">
            <div style="position: relative;">
              <div style="position: absolute;color:#15B3B1;font-weight: bold;top:35%;left:18%;font-size:0.8rem;">M{{index+1}}</div>
              <img style="width: 50px;margin-right: 10px;" src="@/image/pledge_bg.png" alt="">
            </div>
            <div>
              <div style="font-size: 0.7rem;">min</div>
              <div style="font-size: 1rem;">1 MT/{{$t('pledgeList.text1')}}</div>
            </div>
          </div>
          <div style="position: relative;">
            <img style="width: 50px;" src="@/image/pledge_05.png" alt="">
            <div class="pledge_day y_center flex_center">
              <div style="font-size:1.2rem;">{{item[0]}}</div>
              <div style="font-size:0.7rem;">DAY</div>
            </div>
          </div>
        </div>
        <!-- 托管收益/质押总计 -->
        <div class="flex_lr" style="padding: 10px 0;">
          <div class="flex_row" style="width:0;flex:1;">
            <div style="width: 45%;">
              <div class="pledge_title">{{$t('pledgeList.text2')}}</div>
              <div class="pledge_text">{{item[1]}}ULME/{{$t('pledgeList.text1')}}</div>
            </div>
            <div style="width: 45%;">
              <div class="pledge_title">{{$t('pledgeList.text3')}}</div>
              <div class="pledge_text">{{item[2]}}{{$t('pledgeList.text1')}}</div>
            </div>
          </div>
          <i class="pledge_open ifont icone-shouqi hand" :style="index==show_pledge?'transform: rotate(180deg)':''" @click="index==show_pledge?show_pledge=-1:show_pledge=index" />
        </div>
        <!-- 进度条 -->
        <div class="pledge_box2 pledge_title flex_row" style="padding: 5px 10px;">
          <div>{{$t('pledgeList.text14')}}</div>
          <div class="pledge_jdt"><p :style="{width:100/item[2]*(item[2]-item[3])+'%'}" /></div>
          <div>{{(100/item[2]*(item[2]-item[3])).toFixed(2)}}%</div>
        </div>
        <!-- 收起部分 -->
        <div v-if="index == show_pledge">
          <div class="flex_lr" style="margin: 5px 0;">
            <div class="pledge_title">{{$t('pledgeList.text5')}}</div>
            <div class="pledge_text">{{getCount(index,info.user_mt).count}}{{$t('pledgeList.text1')}}</div>
          </div>
          <div class="flex_lr" style="margin: 5px 0;">
            <div class="pledge_title">{{$t('pledgeList.text6')}}</div>
            <div class="pledge_text">{{getCount(index,info.user_mt).mt}}ULME</div>
          </div>
          <div class="pledge_box2">
            <div class="flex_lr" style="padding: 5px 0;">
              <div class="pledge_title">{{$t('pledgeList.text7')}}</div>
              <div class="flex_row hand" style="font-size: 0.7rem;" @click="$toPage('/pledge_mine?type='+index+'&token='+nav)">
                <div>{{$t('pledgeList.text8')}}</div>
                <i class="ifont icone-youjiantou"></i>
              </div>
            </div>
            <div class="pledge_box flex_lr" style="padding: 5px 10px;">
              <input class="pledge_input" v-model="mt_num[index]" type="number" :placeholder="$t('pledgeList.text13')">
            </div>
            <div style="font-size:0.7rem;margin:5px 0;">MT{{$t('pledgeList.text10')}}：{{info.blance_mt}}MT</div>
            <div class="flex_lr">
              <div class="pledge_btn flex_center hand" :style="info.approve_mt>0?'background:#989898':''" @click="approveMT">{{$t('pledgeList.text11')}}</div>
              <div class="pledge_btn flex_center hand" :style="info.approve_mt==0?'background:#989898':''" @click="releaseMt(index,mt_num[index])">{{$t('pledgeList.text12')}}</div>
            </div>          
          </div>
        </div>
      
      </div>
    </div>
       <div class="pledge" v-else>
      <div class="pledge_box" style="padding-bottom: 10px;margin-bottom:20px;" v-for="(item,index) in info.deposit" :key="index">

        <div class="pledge_top flex_lr">
          <div class="flex_row">
            <div style="position: relative;">
              <div style="position: absolute;color:#15B3B1;font-weight: bold;top:35%;left:18%;font-size:0.8rem;">M{{index+1}}</div>
              <img style="width: 50px;margin-right: 10px;" src="@/image/pledge_bg.png" alt="">
            </div>
            <div>
              <div style="font-size: 0.7rem;">min</div>
              <div style="font-size: 1rem;">5000 ULME/{{$t('pledgeList.text1')}}</div>
            </div>
          </div>
          <div style="position: relative;">
            <img style="width: 50px;" src="@/image/pledge_05.png" alt="">
            <div class="pledge_day y_center flex_center">
              <div style="font-size:1.2rem;">{{item[0]}}</div>
              <div style="font-size:0.7rem;">DAY</div>
            </div>
          </div>
        </div>
        <!-- 托管收益/质押总计 -->
        <div class="flex_lr" style="padding: 10px 0;">
          <div class="flex_row" style="width:0;flex:1;">
            <div style="width: 45%;">
              <div class="pledge_title">{{$t('pledgeList.text2')}}</div>
              <div class="pledge_text">{{item[1]}}MT/{{$t('pledgeList.text1')}}</div>
            </div>
            <div style="width: 45%;">
              <div class="pledge_title">{{$t('pledgeList.text3')}}</div>
              <div class="pledge_text">{{item[2]}}{{$t('pledgeList.text1')}}</div>
            </div>
          </div>
          <i class="pledge_open ifont icone-shouqi hand" :style="index==show_pledge?'transform: rotate(180deg)':''" @click="index==show_pledge?show_pledge=-1:show_pledge=index" />
        </div>
        <!-- 进度条 -->
        <div class="pledge_box2 pledge_title flex_row" style="padding: 5px 10px;">
          <div>{{$t('pledgeList.text14')}}</div>
          <div class="pledge_jdt"><p :style="{width:100/item[2]*(item[2]-item[3])+'%'}" /></div>
          <div>{{(100/item[2]*(item[2]-item[3])).toFixed(2)}}%</div>
        </div>
        <!-- 收起部分 -->
        <div v-if="index == show_pledge">
          <div class="flex_lr" style="margin: 5px 0;">
            <div class="pledge_title">{{$t('pledgeList.text5')}}</div>
            <div class="pledge_text">{{getCount(index,info.user).count}}{{$t('pledgeList.text1')}}</div>
          </div>
          <div class="flex_lr" style="margin: 5px 0;">
            <div class="pledge_title">{{$t('pledgeList.text6')}}</div>
            <div class="pledge_text">{{getCount(index,info.user).mt}}MT</div>
          </div>
          <div class="pledge_box2">
            <div class="flex_lr" style="padding: 5px 0;">
              <div class="pledge_title">{{$t('pledgeList.text7')}}</div>
              <div class="flex_row hand" style="font-size: 0.7rem;" @click="$toPage('/pledge_mine?type='+index+'&token='+nav)">
                <div>{{$t('pledgeList.text8')}}</div>
                <i class="ifont icone-youjiantou"></i>
              </div>
            </div>
            <div class="pledge_box flex_lr" style="padding: 5px 10px;">
              <input class="pledge_input" v-model="ulm_num[index]" type="number" :placeholder="$t('pledgeList.text9')">
            </div>
            <div style="font-size:0.7rem;margin:5px 0;">ULME{{$t('pledgeList.text10')}}：{{info.blance_ulm}}ULME</div>
            <div class="flex_lr">
              <div class="pledge_btn flex_center hand" :style="info.approve_ulm>0?'background:#989898':''" @click="approveUlm">{{$t('pledgeList.text11')}}</div>
              <div class="pledge_btn flex_center hand" :style="info.approve_ulm==0?'background:#989898':''" @click="release(index,ulm_num[index])">{{$t('pledgeList.text12')}}</div>
            </div>          
          </div>
        </div>
      
      </div>
    </div>
  </div>
</template>

<script>
import walletHelper from "@/utils/walletHelper.js"
import { Loading } from 'element-ui';

export default {
  components: {},
  data() {
    return {
      show_pledge:-1,
      loading:0,
      nav:0,
      info:[],
      ulm_num:{},
      mt_num:{},
    }
  },
  async mounted () {
    await this.$onLaunched;
    this.init();
  },
  methods: {
    init() {
      const that=this
      walletHelper.getContract('ulmr').methods.getDepositType().call().then(res=>{
          console.log(res)
          that.$set(that.info,'deposit',that.splitArr(res,4))
      })
      walletHelper.getContract('ulmr').methods.depositList(walletHelper.getAddress()).call().then(res=>{
          console.log('user',res)
          that.$set(that.info,'user',that.splitUser(res))
      })
      walletHelper.getContract('ulm').methods.balanceOf(walletHelper.getAddress()).call().then(res=>{
          console.log(res)
          that.$set(that.info,'blance_ulm',walletHelper.Wei(res))
      })
      walletHelper.getContract('ulm').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('ulmr')).call().then(res=>{
          console.log(res)
          that.$set(that.info,'approve_ulm',walletHelper.Wei(res))
      })

      //以下为mt

      walletHelper.getContract('mtr').methods.getDepositType().call().then(res=>{
          console.log(res)
          that.$set(that.info,'deposit_mt',that.splitArr(res,4,true))
      })
      walletHelper.getContract('mtr').methods.depositList(walletHelper.getAddress()).call().then(res=>{
          console.log('user',res)
          that.$set(that.info,'user_mt',that.splitUser(res))
      })

      walletHelper.getContract('mt').methods.balanceOf(walletHelper.getAddress()).call().then(res=>{
          console.log(res)
          that.$set(that.info,'blance_mt',walletHelper.Wei(res))
      })
      walletHelper.getContract('mt').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('mtr')).call().then(res=>{
          console.log(res)
          that.$set(that.info,'approve_mt',walletHelper.Wei(res))
      })

    },
    splitUser(list){
      var arrOuter = []
      for(var a in list){
        if(a%5==0){
          var data={}
              data.nType = list[a]
              data.count = list[Number(a)+1]
              data.time = list[Number(a)+2]
              data.status = list[Number(a)+3]
              data.mt = walletHelper.Wei(list[Number(a)+4])
          arrOuter.push(data)
          // var arr = data.slice(a,a+5)
          // arr[4] = walletHelper.Wei(arr[4])
          // if(!arrOuter[list[a]]){
          //   arrOuter[list[a]] = {arr:[]}
          //   arrOuter[list[a]].num = Number(arr[1])
          //   arrOuter[list[a]].mt = Number(walletHelper.Wei(arr[4]))
          //   arrOuter[list[a]].arr.push(arr)
          // }else{
          //   arrOuter[list[a]].num += Number(arr[1])
          //   arrOuter[list[a]].mt += Number(walletHelper.Wei(arr[4]))
          //   arrOuter[list[a]].arr.push(arr)
          // }
        }
      }
      console.log(arrOuter)
      return arrOuter
    },
    getCount(type,user){
      var data = {
        count:0,
        mt:0
      }
      for(var a in user){
        if(user[a].nType==type){
          data.count+=Number(user[a].count)
          data.mt+=Number(user[a].mt)
        }
      }
      return data
    },
    splitArr(data, senArrLen,isMt=false) {
      //处理成len个一组的数据
      let data_len = data.length;
      let arrOuter_len = data_len % senArrLen === 0 ? data_len / senArrLen : parseInt((data_len /
          senArrLen) + '') + 1;
      let arrSec_len = data_len > senArrLen ? senArrLen : data_len; //内层数组的长度
      let arrOuter = new Array(arrOuter_len); //最外层数组
      let arrOuter_index = 0; //外层数组的子元素下标
      // console.log(data_len % len);
      for (let i = 0; i < data_len; i++) {
          if (i % senArrLen === 0) {
              if(isMt){
              this.mt_num[i/senArrLen] = ''
              }else{
              this.ulm_num[i/senArrLen] = ''

              }
              arrOuter_index++;
              let len = arrSec_len * arrOuter_index;
              //将内层数组的长度最小取决于数据长度对len取余，平时最内层由下面赋值决定
              arrOuter[arrOuter_index - 1] = new Array(data_len % senArrLen);
              if (arrOuter_index === arrOuter_len) //最后一组
                  data_len % senArrLen === 0 ?
                  len = data_len % senArrLen + senArrLen * arrOuter_index :
                  len = data_len % senArrLen + senArrLen * (arrOuter_index - 1);
              let arrSec_index = 0; //第二层数组的索引
              for (let k = i; k < len; k++) { //第一层数组的开始取决于第二层数组长度*当前第一层的索引
                  arrOuter[arrOuter_index - 1][arrSec_index] = data[k];
                  if(arrSec_index==1){
                    arrOuter[arrOuter_index - 1][arrSec_index] = walletHelper.Wei(data[k])
                  }
                  arrSec_index++;
              }
          }
      }
      return arrOuter
    },
    dd(){
        this.loading =Loading.service({
            lock: true,
            text: '交易正在处理中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    },
    closeLoading(msg){
      if(msg&&msg!=''){
        this.$toast({
            title: msg
        });
      }
      this.loading&&this.loading.close();
    },
    redeem(index){
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      this.sendWeb3(walletHelper.getContract('ulmr').methods.withdraw(index)).then(res=>{
          console.log(res)
          that.closeLoading('赎回成功')
          that.init()
      })
    },
    redeemMt(index){
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      this.sendWeb3(walletHelper.getContract('mtr').methods.withdraw(index)).then(res=>{
          console.log(res)
          that.closeLoading('赎回成功')
          that.init()
      })
    },
    releaseMt(index,count){
      count = Number(count)
      if(this.info.approve_mt==0){
        return
      }
      if(count<1||count>20){
        this.$toast('请输入正确的数量')
        return
      }
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      this.sendWeb3(walletHelper.getContract('mtr').methods.release(index,count)).then(res=>{
          console.log(res)
          that.closeLoading('质押成功')
          that.init()
      })
    },
    release(index,count){
      count = Number(count)
      if(this.info.approve_ulm==0){
        return
      }
      if(count<1||count>10){
        this.$toast('请输入正确的数量')
        return
      }
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      this.sendWeb3(walletHelper.getContract('ulmr').methods.release(index,count)).then(res=>{
          console.log(res)
          that.closeLoading('质押成功')
          that.init()
      })
    },
    approveUlm(){
      const that=this
      this.sendWeb3(walletHelper.getContract('ulm').methods.approve(walletHelper.getContractAddress('ulmr'),walletHelper.toWei('9999999999999999'))).then((result)=>{
        console.log(result)
        walletHelper.getContract('ulm').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('ulmr')).call().then(res=>{
          console.log(res)
          that.$set(that.info,'approve_ulm',walletHelper.Wei(res))
        })
        that.closeLoading('ULME授权成功')
      })
    },
    approveMT(){
      const that=this
      this.sendWeb3(walletHelper.getContract('mt').methods.approve(walletHelper.getContractAddress('mtr'),walletHelper.toWei('9999999999999999'))).then((result)=>{
        console.log(result)
        walletHelper.getContract('mt').methods.allowance(walletHelper.getAddress(),walletHelper.getContractAddress('mtr')).call().then(res=>{
            console.log(res)
            that.$set(that.info,'approve_mt',walletHelper.Wei(res))
        })
        that.closeLoading('ULME授权成功')
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.title{
  font-size: 1.1rem;
  font-family: Microsoft YaHei;
  color: #00FFEA;
  margin: 10px 0;
}
  .nav{
    width: 48%;
    background: #041834;
    color: #737373;
    height: 50px;
    border-radius: 5px;
    margin: 10px 0;
    font-size: 1rem;
  }
.pledge{
  .pledge_box{
    width: 100%;
    background: #0C2379;
    border-radius: 5px;
    padding: 0 10px;
    color: #fff;
  }
  .pledge_box2{
    width: 100%;
    background: #041453;
    border-radius: 5px;
    padding: 0 10px;
    color: #fff;
  }
  .pledge_title{
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  .pledge_text{
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: #00FFEA;
  }
  .pledge_open{
    border-radius: 50%;
    border: 1px solid #00FFEA;
    color: #00FFEA;
    font-size: 0.7rem;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
  }
  .pledge_top{
    border-bottom: 1px solid #0B2FB8;
    padding: 15px 0;
    .pledge_day{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: scale(0.8);
    }
  }
  .pledge_jdt{
    width: 0;
    flex: 1;
    height: 6px;
    margin: 0 10px;
    background: #0F5057;
    border-radius: 50px;
    overflow: hidden;
    p{
      height: 100%;
      border-radius: 50px;
      background: #07C6D9;
    }
  }
  .pledge_input{
    background: none;
    color: #fff;
    width: 100%;
  }
  .pledge_input::-webkit-input-placeholder{color:#839CFF;}
  .pledge_input::-moz-placeholder{color:#839CFF;}
  .pledge_input:-ms-placeholder{color:#839CFF;}
  .pledge_btn{
    width: 47%;
    padding: 5px 0;
    color: #000;
    background: #00FFEA;
    border-radius: 5px;
    font-size: 0.7rem;
    margin-bottom: 10px;
  }
}
</style>